import styled from 'styled-components';
import { rgba } from 'polished';
import { themeGet } from '@styled-system/theme-get';

// import bannerImg from 'common/src/assets/image/agencyModern/banner2.png';
import bannerImg from './img/kids_back_01.jpg';

const BannerWrapper = styled.div`
  background-image: url(${bannerImg});
  ${'' /* background-color: #f0ded5; */}
  ${'' /* background-size: 100%; */}
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 80vh;
  position: relative;
  z-index: 0;
  &:before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      to bottom,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.5) 80%,
      rgba(255, 255, 255, 0.8) 90%,
      rgba(255, 255, 255, 0.9) 95%,
      rgba(255, 255, 255, 1) 100%
    ); /* W3C */
    z-index: -1;
  }

  @media only screen and (max-width: 1440px) {
    min-height: 100vh;
  }
  @media only screen and (max-width: 772px) {
    ${'' /* background: #f0ded5; */}
    ${'' /* background-image: none; */}
    min-height: auto;
    background-position: left top;
  }
`;

export const BannerContent = styled.div`
  max-width: 60%;
  width: 100%;
  padding-top: 170px;
  padding-bottom: 160px;
  @media only screen and (max-width: 1600px) {
    max-width: 60%;
    padding-top: 120px;
    padding-bottom: 100px;
  }
  @media only screen and (max-width: 1400px) {
    padding-top: 120px;
    padding-bottom: 100px;
    max-width: 70%;
  }
  @media only screen and (max-width: 1099px) {
    max-width: 100%;
  }
  @media only screen and (max-width: 768px) {
    padding-top: 120px;
    padding-bottom: 90px;
  }
  @media only screen and (max-width: 480px) {
    padding-top: 120px;
    padding-bottom: 70px;
  }
  h1,
  h2 {
    font-size: 2.5rem;
    line-height: 1.3;
    font-weight: 700;
    color: ${themeGet('colors.menu', '#02073e')};
    margin-bottom: 1.5rem;
    letter-spacing: -2px;
    @media only screen and (max-width: 1600px) {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    @media only screen and (max-width: 1440px) {
      margin-bottom: 0.8rem;
      letter-spacing: -1.5px;
    }
    @media only screen and (max-width: 1024px) {
      font-size: 1.5rem;
      margin-bottom: 20px;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 1.5rem;
    }

    @media only screen and (max-width: 768px) {
      font-size: 1.5rem;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 1.5rem;
    }
    @media only screen and (max-width: 480px) {
      font-size: 1.25rem;
      margin-bottom: 1rem;
    }
  }
  .banner-caption {
    color: ${themeGet('colors.paragraph', '#02073E')};
    font-size: 1rem;
    line-height: 2.2;
    font-weight: 400;
    margin-bottom: 2rem;
    @media only screen and (max-width: 1400px) {
      font-size: 1rem;
      max-width: 85%;
    }
    @media only screen and (max-width: 1024px) {
      line-height: 2;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    @media only screen and (max-width: 768px) {
      line-height: 1.8;
      text-align: center;
      max-width: 550px;
      margin: 0 auto;
      margin-bottom: 2rem;
    }
    @media only screen and (max-width: 480px) {
      margin-bottom: 2rem;
    }
  }
`;

export const BannerButtons = styled.div`
  display: flex;
  margin-top: 1.5rem;
  @media only screen and (max-width: 1440px) {
    width: 93%;
  }
  @media only screen and (max-width: 1024px) {
    margin: 0 auto;
    margin-top: 1.5rem;
    max-width: 60%;
    justify-content: center;
  }
  @media only screen and (max-width: 768px) {
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 80%;
  }

  @media only screen and (max-width: 480px) {
    align-items: center;
    width: 100%;
    flex-direction: column;
    max-width: 100%;
  }
  .reusecore__input {
    width: 100%;
  }
  .field-wrapper {
    margin-right: 15px;
    @media only screen and (max-width: 480px) {
      margin-right: 0px;
    }
    input {
      font-family: DM Sans;
      font-size: 16px;
      min-height: 60px;
      padding: 0 24px;
      ::placeholder {
        color: ${rgba('#02073E', 0.4)};
        opacity: 1; /* Firefox */
      }
      &:focus {
        border-color: #ff825c;
      }

      @media only screen and (max-width: 1280px) {
        min-height: 50px;
      }
    }
  }
  button {
    background-color: ${({ theme }) => theme.colors.orange};
    min-width: 200px;
    @media only screen and (max-width: 480px) {
      min-width: 100%;
      margin-top: 1rem;
    }
  }
`;

export const SponsoredBy = styled.div`
  ${'' /* display: flex; */}
  ${'' /* align-items: center; */}
  margin-top: 50px;
  justify-content: space-between;
  @media only screen and (max-width: 1440px) {
    margin-top: 40px;
    width: 100%;
    justify-content: space-between;
  }
  @media only screen and (max-width: 1024px) {
    justify-content: center;
  }
  @media only screen and (max-width: 480px) {
    align-items: flex-start;
    flex-direction: column;
    margin-top: 30px;
  }

  .sponsoredBy {
    ${'' /* color: ${rgba('#566272', 0.6)}; */}
    color: ${({ theme }) => theme.colors.white};
    font-size: 0.85rem;
    font-weight: 600;
    margin-right: 1.5rem;
    margin-bottom: 0.5rem;
    text-shadow: 0px 3px 5px rgba(0, 0, 0, 0.8);

    ${'' /* @media only screen and (max-width: 480px) {
      margin-bottom: 15px;
    } */}
  }
`;

export const ImageGroup = styled.div`
  ${'' /* display: flex;
  align-items: center; */}
  display: grid;
  ${'' /* grid-auto-flow: column; */}
  grid-gap: 0.5rem;
  grid-template-columns: repeat(auto-fit, minmax(150px, 150px));
  img {
    max-width: 150px;
    ${'' /* &:not(:last-child) {
      margin-right: 2rem;
    } */}
    ${'' /* @media only screen and (max-width: 480px) {
      max-width: 27%;
    } */}
  }
`;

export const ImageHeading = styled.div`
  width: 70%;
  padding-bottom: 1.5rem;
  img {
    max-width: 350px;
  }
  @media (max-width: 1024px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }
  @media (max-width: 574px) {
    width: 100%;
    img {
      max-width: 100%;
      height: auto;
      object-fit: contain;
    }
  }
`;

export default BannerWrapper;
