import React from 'react';
// import { Link } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
// import BlogPost from 'common/src/components/BlogPost';
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  // LinkArea,
  Text,
  // PostArea,
  BannerButtons
} from './blogSection.style';

const BlogSection = ({ landing }) => {
  return (
    <SectionWrapper id="diplomado">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content="Diplomado" />
            <Text>
              Como parte de su eje de promoción de la salud mental y bienestar
              emocional de la comunidad, la iniciativa Aprendiendo y
              Cuidándo[Nos] en Comunidad desarrolla un programa de formación de
              agentes locales y líderes comunitarios de los territorios
              participantes. Este programa busca contar con actores comunitarios
              preparados en primeros auxilios psicológicos y en el desarrollo de
              recursos psicosociales a nivel local para así afrontar la
              emergencia sanitaria y otras catástrofes que las comunidades
              puedan experimentar en el futuro.
            </Text>
          </TitleArea>
        </SectionHeader>
        <BannerButtons>
          {/* <Link to="/app/login">
            <Button title="Ir a Diplomado" type="button" />
          </Link> */}
          <a
            href="https://www.uai.cl/online/postgrados/diplomados/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button title="Ir a Diplomado" type="button" />
          </a>
        </BannerButtons>
      </Container>
    </SectionWrapper>
  );
};

export default BlogSection;
