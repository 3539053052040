import React from 'react';
// import { Link } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';
// import BlogPost from 'common/src/components/BlogPost';
import TallerBlock from './TallerBlock';
import SectionWrapper, {
  SectionHeader,
  TitleArea,
  // LinkArea,
  Text,
  PostArea
} from './blogSection.style';

// /* ------------------------------------ */
// // Blog post data
// /* ------------------------------------ */
// import thumb1 from 'common/src/assets/image/charity/blog/thumb-1.png';
// import thumb2 from 'common/src/assets/image/charity/blog/thumb-2.png';

const BlogSection = () => {
  return (
    <SectionWrapper id="talleres">
      <Container width="1260px">
        <SectionHeader>
          <TitleArea>
            <Heading content="Talleres" />
            <Text>
              Otra de las acciones de promoción y prevención en salud mental y
              bienestar emocional que ofrece el programa Aprendiendo y
              Cuidándo[Nos] en Comunidad, consiste en la implementación de
              diversos talleres para estudiantes, docentes y agentes locales,
              así como para madres, padres y apoderados de las localidades
              participantes
            </Text>
          </TitleArea>
        </SectionHeader>
        <PostArea>
          <TallerBlock
            // thumbUrl={thumb1}
            title="TALLER PRIMEROS AUXILIOS PSICOLÓGICOS"
            excerpt="Los Primeros Auxilios Psicológicos son un conjunto de técnicas concretas basadas en la evidencia, que se utilizan para calmar, contener y derivar a niños y niñas, adolescentes y adultos que estén pasando por una situación de intenso estrés, que conlleva un desborde emocional. Los Primeros Auxilios Psicológicos pueden ser efectuados por todo tipo de personas que hayan sido capacitadas, sin que provengan del área de la salud."
            // footer={
            //   <div className="details-table">
            //     <div className="item">
            //       <div className="detail-label">Dirigido a:</div>
            //       <div className="detail-text">
            //         Agentes comunitarios y personas interesadas
            //       </div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Modalidad:</div>
            //       <div className="detail-text">Online sincrónico</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Duración:</div>
            //       <div className="detail-text">4 talleres de 1 hr c/u</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Fecha inicio:</div>
            //       <div className="detail-text">Por definir</div>
            //     </div>
            //   </div>
            // }
            // link={
            //     <Link to="/app/login">
            //       <Button title="Inscríbete" />
            //     </Link>
            // }
          />
          <TallerBlock
            // thumbUrl={thumb1}
            title="TALLERES PARA PADRES/MADRES/CUIDADORES"
            excerpt="El objetivo principal de los talleres para padres, madres y cuidadores es entregar un entorno que posibilite expresar y normalizar sus sentimientos asociados a su rol en la crianza. Y además, brindar un espacio seguro para proporcionar apoyo y comprensión necesarios para expresar libremente sus dificultades asociadas al proceso de criar."
            // footer={
            //   <div className="details-table">
            //     <div className="item">
            //       <div className="detail-label">Dirigido a:</div>
            //       <div className="detail-text">
            //         Padres, madres y cuidadores de niños y niñas preescolares y
            //         escolares
            //       </div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Modalidad:</div>
            //       <div className="detail-text">Online sincrónico</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Duración:</div>
            //       <div className="detail-text">8 talleres de 1 hr c/u</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Fecha inicio:</div>
            //       <div className="detail-text">Por definir</div>
            //     </div>
            //   </div>
            // }
            // link={
            //     <Link to="/app/login">
            //       <Button title="Inscríbete" />
            //     </Link>
            // }
          />
          <TallerBlock
            // thumbUrl={thumb1}
            title="TALLERES PARA NIÑOS/AS Y ADOLESCENTES"
            excerpt="Se trata de un taller práctico en modalidad online que se plantea como un espacio de Promoción de Salud Mental, destinado a niños/as, preadolescentes y adolescentes. El objetivo general es mejorar la calidad de vida de los niños/as, preadolescentes y adolescentes a través del juego y el clown."
            // footer={
            //   <div className="details-table">
            //     <div className="item">
            //       <div className="detail-label">Dirigido a:</div>
            //       <div className="detail-text">Niños</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Modalidad:</div>
            //       <div className="detail-text">Online sincrónico</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Duración:</div>
            //       <div className="detail-text">4 talleres de 1 hr c/u</div>
            //     </div>
            //     <div className="item">
            //       <div className="detail-label">Fecha inicio:</div>
            //       <div className="detail-text">Por definir</div>
            //     </div>
            //   </div>
            // }
            // link={
            //     <Link to="/app/login">
            //       <Button title="Inscríbete" />
            //     </Link>
            // }
          />
        </PostArea>
      </Container>
    </SectionWrapper>
  );
};

export default BlogSection;
