import React, { Fragment } from 'react';
import Sticky from 'react-stickynode';
import { ThemeProvider } from 'styled-components';
import { bhpTheme } from 'common/src/theme/bhp';
import { ResetCSS } from 'common/src/assets/css/style';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';

// import Navbar from '../containers/AgencyModern/Navbar';
// import Banner from '../containers/AgencyModern/Banner';
import Navbar from '../containers/BHP/Navbar';
import Banner from '../containers/BHP/Banner';
import DiplomadoSection from '../containers/BHP/DiplomadoSection';
import SponsoredLogos from '../containers/BHP/SponsoredLogos';
import TalleresSection from '../containers/BHP/TalleresSection';

// import FeatureSection from '../containers/Charity/FeatureSection';
// import WorkSection from '../containers/Charity/WorkSection';
// import MilestoneBlock from '../containers/Charity/MilestoneBlock';
// import HumanityBlock from '../containers/Charity/HumanityBlock';
// import PromotionBlock from '../containers/Charity/PromotionBlock';
// import BlogSection from '../containers/Charity/BlogSection';
// import Footer from '../containers/Charity/Footer';
// import FeatureSection from '../containers/BHP/FeatureSection';
// import WorkSection from '../containers/BHP/WorkSection';
// import MilestoneBlock from '../containers/BHP/MilestoneBlock';
import ChatOnline from '../containers/BHP/ChatOnline';
import HumanityBlock from '../containers/BHP/HumanityBlock';
import PromotionBlock from '../containers/BHP/PromotionBlock';
// import BlogSection from '../containers/BHP/BlogSection';
import Footer from '../containers/BHP/Footer';
// import {
//   GlobalStyle,
//   CharityWrapper,
//   ContentWrapper
// } from '../containers/Charity/charity.style';
import {
  GlobalStyle,
  BHPWrapper,
  ContentWrapper
} from '../containers/BHP/bhp.style';
import SEO from '../components/seo';

export default () => {
  return (
    <ThemeProvider theme={bhpTheme}>
      <Fragment>
        <SEO title="Aprendiendo y Cuidando[Nos] en Comunidad" />
        <ResetCSS />
        <GlobalStyle />
        <BHPWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar />
            </DrawerProvider>
          </Sticky>
          <ContentWrapper>
            <Banner />
            <SponsoredLogos />
            <DiplomadoSection />
            <TalleresSection landing />
            {/* <FeatureSection /> */}
            {/* <WorkSection /> */}
            {/* <MilestoneBlock /> */}
            <ChatOnline />
            <PromotionBlock />
            <HumanityBlock />
            {/* <BlogSection /> */}
          </ContentWrapper>
          <Footer />
        </BHPWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
