import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
// import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  // List,
  // Item,
  ImageWrapper
} from './humanityBlock.style';

/* ------------------------------------ */
// Humanity block data
/* ------------------------------------ */
import humanityGlob from 'common/src/assets/image/charity/humanity-glob.png';

// export const humanityData = {
//   image: humanityGlob,
//   text:
//     'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.',
//   lists: [
//     {
//       id: 1,
//       text: 'Let them drink pure'
//     },
//     {
//       id: 2,
//       text: 'Ensure them medicare'
//     },
//     {
//       id: 3,
//       text: 'create opportunity of education'
//     }
//   ]
// };

const HumanityBlock = ({ row, col }) => {
  // const { slogan, title, text, lists, image } = humanityData;
  return (
    <BlockWrapper>
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image src={humanityGlob} alt="Educación digital bhp niños" />
            </ImageWrapper>
          </Box>
          <Box className="col" {...col}>
            <ContentWrapper>
              {/* <Heading as="h5" content="Enfoque Transversal" /> */}
              {/* <Heading content="Un enfoque de respuesta en 360°" /> */}
              <Text content="La iniciativa Aprendiendo y Cuidando[Nos] en Comunidad es parte del ámbito de Apoyo Emocional y Resiliencia de la RED, que apunta a fomentar mecanismos de prevención de riesgos psicosociales, promover la salud mental y proveer apoyo y atención especializada, a fin de asegurar una mayor resiliencia y facilitar la visión de la actual crisis como una oportunidad de aprendizaje y cambio. Para esto, el programa implementa acciones de promoción, prevención e intervención en salud mental y bienestar emocional para estudiantes, sus familias, así como para agentes locales como docentes y líderes comunitarios." />
              {/* <List>
                <Item>Posicionar el cambio de paradigma del aprendizaje</Item>
                <Item>Potenciar el usos de la innovación y tecnología</Item>
                <Item>
                  Movilizar la transformación de la educación pública en Chile
                </Item>
                <Item>Impulsar políticas públicas en educación</Item>
              </List> */}
            </ContentWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// HumanityBlock style props
HumanityBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// HumanityBlock default style
HumanityBlock.defaultProps = {
  // HumanityBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // HumanityBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default HumanityBlock;
