import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Image from 'common/src/components/Image';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import BlockWrapper, {
  ContentWrapper,
  // List,
  // Item,
  ImageWrapper
} from './promotionBlock.style';

/* ------------------------------------ */
// Promotion block data
/* ------------------------------------ */
// import happyKids from 'common/src/assets/image/charity/promotion.svg';
import ACNCLogo from './img/ACNC.png';

// export const promotionData = {
//   text1:
//     'We exist to permanently end violent conict and exploitation facing our world’s most isolated and unprotected communities.',
//   text2:
//     'We partner with local visionaries in volatile conict zones to build community-run initiatives and drive global policy change to end violent conict, save lives, and give communities the safety they deserve.',
//   lists: [
//     {
//       id: 1,
//       text: 'Let them drink pure'
//     },
//     {
//       id: 2,
//       text: 'Ensure them medicare'
//     },
//     {
//       id: 3,
//       text: 'create opportunity of education'
//     }
//   ],
//   image: happyKids
// };

const PromotionBlock = ({ row, col }) => {
  // const { slogan, title, text1, text2, lists, image } = promotionData;

  return (
    <BlockWrapper id="nosotros">
      <Container width="1260px">
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <ContentWrapper>
              <Heading as="h5" content="Nosotros" />
              {/* <Heading content="Colaboremos a transformar el sistema de educación" /> */}
              <Text content="El programa Aprendiendo y Cuidándo[Nos] en Comunidad es una iniciativa de la Escuela de Psicología de la Universidad Adolfo Ibáñez, parte de la Red Educación Digital, un espacio colaborativo impulsado por la Fundación BHP y que se enmarca en los programas educacionales de la Fundación a nivel global." />
              <Text content="La Red en Chile integra seis proyectos: Aprendo en Casa, Comunidad Mujer, Enseña Chile, Fundación Chile, Fundación Kodea y la Universidad Adolfo Ibáñez, desde su Escuela de Psicología." />
              {/* <a href="#1" className="learn__more-btn">
                <span className="hyphen" />
                <span className="btn_text">Learn More </span>
              </a> */}
            </ContentWrapper>
          </Box>
          <Box className="col" {...col}>
            <ImageWrapper>
              <Image
                src={ACNCLogo}
                alt="Aprendiendo y Cuidando[Nos] en Comunidad"
              />
            </ImageWrapper>
          </Box>
        </Box>
      </Container>
    </BlockWrapper>
  );
};

// PromotionBlock style props
PromotionBlock.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object
};

// PromotionBlock default style
PromotionBlock.defaultProps = {
  // PromotionBlock row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px'
  },
  // PromotionBlock col default style
  col: {
    width: ['100%', '50%', '50%'],
    pl: '15px',
    pr: '15px',
    mb: '30px'
  }
};

export default PromotionBlock;
