import React from 'react';
import { Link } from 'gatsby';
import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
import Button from 'common/src/components/Button';
import Heading from 'common/src/components/Heading';
// import Input from 'common/src/components/Input';
import Container from 'common/src/components/UI/ContainerTwo';
import BannerWrapper, {
  BannerContent,
  BannerButtons,
  // SponsoredBy,
  // ImageGroup,
  ImageHeading
} from './banner.style';

// import paypal from 'common/src/assets/image/agencyModern/paypal.png';
// import google from 'common/src/assets/image/agencyModern/google.png';
// import dropbox from 'common/src/assets/image/agencyModern/dropbox.png';
// import UAILOGO from './img/UAI_LOGO.png';
import ACNCLOGO from './img/ACNC.png';

const Banner = () => {
  return (
    <BannerWrapper id="home">
      <Container>
        <BannerContent>
          <ImageHeading>
            <Image
              src={ACNCLOGO}
              alt="Aprendiendo y Cuidando(Nos) en Comunidad"
            />
          </ImageHeading>
          {/* <Heading as="h1" content="Aprendiendo y Cuidando(Nos) en Comunidad" /> */}
          <Heading
            as="h2"
            content="Programa de la Escuela de Psicología UAI, parte de la Red de Educación Digital"
          />
          <Text
            className="banner-caption"
            content="Buscamos desarrollar un ecosistema de acompañamiento a comunidades escolares con el fin de asegurar la salud emocional y bienestar de los estudiantes, sus familias y los agentes educativos. Para esto, implementamos acciones de promoción, prevención e intervención. Si le interesa inscribirse y participar en las diversas actividades del programa, ingrese aquí:"
          />
          <BannerButtons>
            <Link to="/app/login">
              <Button title="Ingresar" type="button" />
            </Link>
          </BannerButtons>
        </BannerContent>
      </Container>
    </BannerWrapper>
  );
};

export default Banner;
