import React from 'react';
// import { Link } from 'gatsby';
import Container from 'common/src/components/UI/Container';
import Heading from 'common/src/components/Heading';
// import Button from 'common/src/components/Button';
// import Text from 'common/src/components/Text';
import Image from 'common/src/components/Image';
// import BlogPost from 'common/src/components/BlogPost';
import SectionWrapper, {
  SectionHeader,
  // LinkArea,
  // Text,
  TitleArea,
  SponsoredBy,
  ImageGroup
} from './blogSection.style';

import BHPLOGO from './img/bhp_fundation_logo.png';
import FMELOGO from './img/Logo-turquesa_fondo-transparente.png';
import TCOLOGO from './img/ComunidadesCoquimbo.png';
import DEMLOGO from './img/STGOEduca.png';

const SponsoredLogos = () => {
  return (
    <SectionWrapper>
      <Container width="1260px">
        <SectionHeader>
          <SponsoredBy>
            <TitleArea>
              <Heading content="Apoyado Por:" />
            </TitleArea>

            <ImageGroup>
              <Image src={BHPLOGO} alt="Fundación BHP" />
              <Image src={FMELOGO} alt="Fundación Minera Escondida" />
              {/* <Image src={BHPLOGO} alt="Corporacion Antofagasta" /> */}
              <Image src={TCOLOGO} alt="Territorio Coquimbo" />
              <Image src={DEMLOGO} alt="DEM Santiago" />
            </ImageGroup>
          </SponsoredBy>
        </SectionHeader>
      </Container>
    </SectionWrapper>
  );
};

export default SponsoredLogos;
